 .steps ul {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  list-style: none;
  padding-left: 0;
}
.steps li,  .steps li.current {
  outline: none;
  -o-outline: none;
  -ms-outline: none;
  -moz-outline: none;
  -webkit-outline: none;
  position: relative;
  padding-bottom: 3px;
  padding-right: 63px
}
 .steps li:last-child {
  padding-right: 0
}

 .steps li .current-info {
  display: none
}

 .steps li::before {
  position: absolute;
  content: "";
  background: #e5e5e5;
  width: 168px;
  height: 6px;
  top: 20%;
  left: 47%
}

 .steps li:last-child::before {
  content: none
}
 .steps li.current::after {
  position: absolute;
  content: "";
  width: 45px;
  height: 3px;
  background: #ccc;
  top: 100%;
  left: 25%
}

 .steps li a {
  text-decoration: none;
  outline: none;
  -o-outline: none;
  -ms-outline: none;
  -moz-outline: none;
  -webkit-outline: none
}

 .steps li a .title {
  text-align: center
}

 .steps li a .title span {
  display: block;
  font-size: 16px
}

 .steps li a .title .step-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background: #ccc;
  margin: 0 auto;
  position: relative;
  outline: none;
  -o-outline: none;
  -ms-outline: none;
  -moz-outline: none;
  -webkit-outline: none;
  color: #fff;
  font-size: 20px
}

 .steps li a .step-icon .ts {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%)
}

 .steps li.current a .step-icon, .steps li.current a:active .step-icon, .steps .done::before, .steps li.done a .step-icon, .steps li.done a:active .step-icon {
  background: #6eba2a
}

 .steps li.current a .step-icon, .steps li.current a:active .step-icon {
  box-shadow: 0 5px 18px 0 rgba(0,0,0,.2);
  -o-box-shadow: 0 5px 18px 0 rgba(0,0,0,.2);
  -ms-box-shadow: 0 5px 18px 0 rgba(0,0,0,.2);
  -moz-box-shadow: 0 5px 18px 0 rgba(0,0,0,.2);
  -webkit-box-shadow: 0 5px 18px 0 rgba(0,0,0,.2)
}

 .steps li a .step-number {
  color: #666;
  font-weight: 400;
  padding: 17px 0 8px
}

 .steps li a .step-text {
  color: #333;
  font-weight: 600;
  padding-bottom: 8px
}

 .content {
  background: #359bfa;
  border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 32px 115px 23px 75px
}

 .content h2 {
  display: none
}